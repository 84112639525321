export default interface ComponentTreeItem {
	'@id': string,
	'@type': string,
	name: string,
	description: string,
	children: [ComponentTreeItem?],
}

/**
 * TypeGuard to determine if object is a ComponentTreeItem
 *
 * @param obj
 */
export function isComponentTreeItem(obj: ComponentTreeItem | object | undefined): obj is ComponentTreeItem {
	return obj !== undefined && 'children' in obj;
}
