import { computed, type ComputedRef } from 'vue';
import { useWindowSize } from '@vueuse/core';
import breakpointsJson from '@/config/breakpoints.json';
const breakpointsKey = {
	XS: 'xs',
	SM: 'sm',
	MD: 'md',
	LG: 'lg',
	XL: 'xl',
	XXL: 'xxl'
}

type Breakpoints = typeof breakpointsKey[keyof typeof breakpointsKey];
const breakpoints: Record<string, number> = breakpointsJson;
interface ScreenSize {
	currentScreenSize: ComputedRef<string>;
	isScreenSizeEqual: (size: Breakpoints) => boolean;
	isScreenSizeSmallerThan: (size: Breakpoints) => boolean;
	isScreenSizeLargerThan: (size: Breakpoints) => boolean;
	isScreenSizeBetween: (start: Breakpoints, end: Breakpoints) => boolean;
}

export function useScreenSize(): ScreenSize {
	const { width } = useWindowSize();

	const currentScreenSize = computed(() => {
		if (width.value < breakpoints.sm) return breakpointsKey.XS
		if (width.value < breakpoints.md) return breakpointsKey.SM
		if (width.value < breakpoints.lg) return breakpointsKey.MD
		if (width.value < breakpoints.xl) return breakpointsKey.LG
		if (width.value < breakpoints.xxl) return breakpointsKey.XL

		return breakpointsKey.XXL
	})

	const isScreenSizeEqual = (size: Breakpoints) => {return currentScreenSize.value === size}
	const isScreenSizeSmallerThan = (size: Breakpoints) => {return width.value < breakpoints[size]}
	const isScreenSizeLargerThan = (size: Breakpoints) => {return width.value >= breakpoints[size]}
	const isScreenSizeBetween = (start: Breakpoints, end: Breakpoints) => {return width.value >= breakpoints[start] && width.value < breakpoints[end]}

	return {
		currentScreenSize,
		isScreenSizeEqual,
		isScreenSizeSmallerThan,
		isScreenSizeLargerThan,
		isScreenSizeBetween
	};
}
