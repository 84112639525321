import { defineStore, storeToRefs } from 'pinia';
import { computed, readonly, ref, watch } from 'vue';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type RequestAuthInterface from '@/type/interface/Request/RequestAuthInterface';
import useJwtStore from './jwt';
import useRefreshTokenStore from './refreshToken';
import UserRoles from '@/utils/definitions/UserRoles';
import type UserJwtInterface from '../type/interface/UserJwtInterface';
import type { MutableType } from '@/type';

const useUserStore = defineStore('userStore', () => {
	const userRoles = ref<UserJwtInterface['roles']>();
	const name = ref<string>('anonymous');
	const jwtStore = useJwtStore();
	const refreshTokenStore = useRefreshTokenStore();

	const { token } = storeToRefs(jwtStore);

	watch(token, (value) => {
		if (value === undefined) {
			userRoles.value = undefined;
			name.value = 'anonymous';

			return;
		}
		userRoles.value = [...value.roles] as MutableType<UserJwtInterface['roles']>;
		name.value = value.username;
	}, { deep: true, immediate: true });

	const isAdmin = computed(() => {
		if (!isLoggedIn.value || !userRoles.value) {
			return false;
		}

		return userRoles.value.includes(UserRoles.ADMIN);
	});

	const isLoggedIn = computed(() => {
		if (!token) return false;

		return token.value !== undefined;
	});

	/**
	 * @public
	 * @param username
	 * @param password
	 */
	async function login(username: string, password: string) {
		const backendWrapperAuth = new useBackendWrapper<RequestAuthInterface>('token/auth');
		const {
			error: requestError,
			data: requestData
		} = await backendWrapperAuth.post({ username, password });

		if (requestError || !requestData) {
			logout();

			return false;
		}

		// Send JWT and its RefreshToken to the dedicated stores
		if (requestData.token) {
			jwtStore.setTokenPayload(requestData.token);
		}
		if (requestData.refresh_token && requestData.rte) {
			refreshTokenStore.setToken(requestData.refresh_token, requestData.rte);
		}

		return isLoggedIn.value;
	}

	/**
	 * @public
	 */
	function logout() {
		jwtStore.removeToken();
		refreshTokenStore.removeToken();
		userRoles.value = undefined;
	}

	return {
		isLoggedIn,
		isAdmin,
		name: readonly(name),
		login,
		logout
	};
});

export default useUserStore;
