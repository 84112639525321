<template>
	<ElMainMenu
		v-if="menuItems.length > 0"
		:menu-items="menuItems"
		:active-menu="activeMenu"
	/>
</template>

<script setup lang="ts">
import { ElMainMenu } from '@energielenker/common-component-bundle';
import mainMenuStructure from '@/mainMenuStructure';
import { ref, unref, watch } from 'vue';
import { useRouter } from 'vue-router';

const menuItems = ref(mainMenuStructure());
const activeMenu = ref('PV-Anlage');

const router = useRouter();

// For some reason we have to watch on currentRoute instead of creating a computed var to hold activeMenu.
// If using a computed this generates a flaky error in WebKit Browser (Apple Safari)
watch(() => { return router.currentRoute; }, (value) => {
	const currentRoute = unref(value);
	activeMenu.value = currentRoute.meta.caption;
});
</script>

<style lang="scss">
.router-link-active {
	font-weight: 700;
	color: var(--color-primary-darker-blue)!important;
}

@include media-breakpoint-down(lg) {
		#app{
			.sidebar{
				.main-menu{
					height: auto;
					width: 100%;
					.list-unstyled{
						display: flex ;
						width: 100%;
						justify-content: space-around;
						margin:0 ;
						>li{
							width: auto ;
							margin-bottom:0 !important;
							div:first-child{
								margin-bottom:0 !important;
								padding:0;
								a{
									display: flex;
									flex-direction: column;
									align-items: center;
									font-size:12px;
									&.router-link-exact-active{
										font-weight: normal;
										.icon{
											background-color: var(--color-primary-light-grey);
										}
									}
									.icon{
										width: 40px;
										height: 40px;
										display: flex;
										justify-content: center;
										align-items: center;
										margin:0 !important;
										&::before{
											font-size: 22px ;
											color: var(--color-primary-blue)
										}
									}
								}
							}
						}
					}
				}
			}
		}
}
</style>
