import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type RequestError from '@/utils/function/RequestError';
import type { RequestAbstractComponentInterface, RequestHemsComponentCollectionInterface } from '@/type/interface/Request';
import { ElTreeClass, ElTreeItem } from '@energielenker/common-component-bundle';
import { defineStore } from 'pinia';
import { ref, watch } from 'vue';
import type { ComponentTreeItem } from '@/type/interface/component/ComponentTree';
import { isComponentTreeItem } from '@/type/interface/component/ComponentTree';
import gettext from '@/gettext';

/**
 * This pinia store, contains the componentTree
 * Different designs for the tree are used, to define how the icons in the tree should look like
 */
export const useComponentTreeStore = defineStore('componentTree', () => {
	const treeDesigns = ref<[{design: string, tree: ElTreeClass}?]>([]);
	const selectedParentId = ref<string>();
	const baseTree = ref<ComponentTreeItem>();
	const rootId = ref<string>();
	const activeItemId = ref<string>('');
	const { $gettext } = gettext;

	// TODO: Make this an enum in future. probably withgit statuws

	//  https://elsmartsolutions.atlassian.net/browse/ENBAS-411
	const availableDesigns = [
		'default',
		'addComponentphotovoltaic',
		'addComponentheatpump',
		'addComponentmeters',
		'addComponentchargepoint',
		'addComponentheatstorage',
		'addComponentbatterystorage'
	];

	watch(activeItemId, () => {
		updateActiveElement();
	});

	/**
	 * Fetch the tree from backend, and create the baseTree and its treeDesigns
	 * @public
	 * @param forceRefresh
	 */
	async function load(forceRefresh = false) {
		if (baseTree.value && !forceRefresh) {
			return;
		}

		cleanupTree();
		const backendWrapperHems = new useBackendWrapper<RequestHemsComponentCollectionInterface>('hems');
		const { data: requestGetData } = await backendWrapperHems.get();
		if (requestGetData) {
			const rootMember = requestGetData['hydra:member'];
			if (rootMember && rootMember.length > 0) {
				rootId.value = rootMember[0]['@id'];
				baseTree.value = await buildBaseTreeRecursive(rootMember[0]);
				buildElTreeDesigns();
			}
		}
		updateActiveElement();
	}

	/**
	 * Update the activeItem in the tree
	 * @private
	 */
	function updateActiveElement() {
		const treeDesign = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		const activeItem = findElementInTreeById(activeItemId.value?activeItemId.value:'new', false);
		if (treeDesign?.tree && isElTreeItem(activeItem)) {
			unsetActiveItem();
			treeDesign.tree.activeItem = activeItem;
		} else if (treeDesign?.tree) {
			unsetActiveItem();
		}
	}

	/**
	 * Unset the activeItem in the tree
	 * @private
	 */
	function unsetActiveItem() {
		const treeDesign = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		if (treeDesign?.tree) {
			treeDesign.tree.activeItem = new ElTreeItem({ color: '', icon: '', title: '', description: '', id: '', children: [], open: true });
		}
	}

	/**
	 * Cleanup the tree, and remove all elements which are not stored (id='new')
	 * @public
	 */
	function cleanupTree() {
		const treeDesign = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		if (treeDesign?.tree) {
			cleanupTreeRecursive(<ElTreeClass>treeDesign.tree);
			unsetActiveItem();
		}
	}

	/**
	 * Recursive cleanup the tree
	 * @private
	 * @param tree
	 */
	function cleanupTreeRecursive(tree:ElTreeClass|ElTreeItem){
		let children: ElTreeItem[];
		if (isElTreeClass(tree)) {
			children = tree.elements;
		} else {
			children = tree.children;
		}

		for (const [index, child] of children.entries()) {
			if ('new' === child.id) {
				children = children.splice(index, 1);

				return;
			}
		}

		for (const child of children) {
			cleanupTreeRecursive(child);
		}

		return;
	}

	/**
	 *
	 * Create different treedesigns, based on the baseTree
	 * @private
	 */
	function buildElTreeDesigns() {
		if (!baseTree.value) return;

		for (const design of availableDesigns) {
			const tree = buildTreeDesign(baseTree.value, design);
			if (isElTreeClass(tree)) {
				unsetActiveItem();
				const treeDesign = treeDesigns.value.find((element) => {
					return element?.design === design;
				});
				if (treeDesign) {
					//treeDesign.tree = tree; //not working ;-( because it is not updating the ref relation, so it needs to be done like this:
					syncTreeRemoveDeletedElementsRecursive(<ElTreeClass>treeDesign.tree, tree);
					syncTreeAddNewElementsRecursive(tree, <ElTreeClass>treeDesign.tree);
				} else {
					treeDesigns.value.push({ design, tree });
				}
			}
		}
	}

	/**
	 * Sync the designtrees with the basetree and add missing elements
	 * @private
	 * @param sourceTree
	 * @param targetTree
	 */
	function syncTreeAddNewElementsRecursive(sourceTree: ElTreeClass|ElTreeItem, targetTree: ElTreeClass|ElTreeItem) {
		let children: ElTreeItem[];
		if (isElTreeClass(sourceTree)) {
			children = sourceTree.elements;
		} else {
			children = sourceTree.children;
		}

		for (const child of children) {
			const foundParent = findElementInTreeByIdRecursive(child.id, sourceTree);
			if (foundParent && !findElementInTreeByIdRecursive(child.id, targetTree)) {
				if (isElTreeClass(foundParent)) {
					addElementByIdRecursive(child, '', targetTree);
				} else {
					addElementByIdRecursive(child, foundParent.id, targetTree);
				}
			}
		}

		for (const child of children) {
			syncTreeAddNewElementsRecursive(child, targetTree);
		}

		return;
	}

	/**
	 * Add an element to the tree
	 * @private
	 * @param elementToAdd
	 * @param parentId
	 * @param targetTree
	 */
	// eslint-disable-next-line complexity
	function addElementByIdRecursive(elementToAdd: ElTreeItem, parentId: string, targetTree: ElTreeClass|ElTreeItem) {
		if (!parentId) {
			if (isElTreeClass(targetTree)) {
				targetTree.elements.push( elementToAdd );
			} else {
				targetTree.children.push( elementToAdd );
			}

			return;
		}

		let children: ElTreeItem[];
		if (isElTreeClass(targetTree)) {
			children = targetTree.elements;
		} else {
			children = targetTree.children;
		}

		for (const child of children) {
			if (child.id === parentId) {
				if (isElTreeClass(child)) {
					child.elements.push( elementToAdd );
				} else {
					child.children.push( elementToAdd );
				}

				return;
			}
		}

		for (const child of children) {
			addElementByIdRecursive(elementToAdd, parentId, child);
		}

		return;
	}

	/**
	 * Sync the designtrees with the basetree and remove deleted elements
	 * @private
	 * @param sourceTree
	 * @param targetTree
	 */
	function syncTreeRemoveDeletedElementsRecursive(sourceTree: ElTreeClass|ElTreeItem, targetTree: ElTreeClass|ElTreeItem) {
		let children: ElTreeItem[];
		if (isElTreeClass(sourceTree)) {
			children = sourceTree.elements;
		} else {
			children = sourceTree.children;
		}

		for (const [index, child] of children.entries()) {
			if (!findElementInTreeByIdRecursive(child.id, targetTree)) {
				children = children.splice(index, 1);

				return;
			}
		}

		for (const child of children) {
			syncTreeRemoveDeletedElementsRecursive(child, targetTree);
		}

		return;
	}

	/**
	 * Find an element in the tree
	 * @public
	 * @param id
	 */
	function findElementInTreeById(id: string, returnParent = true): ElTreeClass|ElTreeItem|undefined {
		const treeDesign = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		if (treeDesign?.tree) {
			return findElementInTreeByIdRecursive(id, <ElTreeClass>treeDesign.tree, returnParent);
		}

		return undefined;
	}

	/**
	 * Find an element in the tree by recursion
	 * @private
	 * @param id
	 * @param tree
	 */
	// eslint-disable-next-line complexity
	function findElementInTreeByIdRecursive(id: string, tree: ElTreeClass|ElTreeItem, returnParent = true): ElTreeClass|ElTreeItem|undefined {
		let ret: ElTreeClass|ElTreeItem|undefined;
		let children: ElTreeItem[];
		if (isElTreeClass(tree)) {
			children = tree.elements;
		} else {
			children = tree.children;
		}

		for (const child of children) {
			if (child.id === id) {
				if (returnParent) {
					return tree;
				} else {
					return child;
				}
			} else {
				const atIdExtracted = child.id.match(/\/api\/(.*)\/(\w{8}-\w{4}-\w{4}-\w{4}-\w{12}).*/);
				if (atIdExtracted?.length && atIdExtracted?.length > 1) {
					if (atIdExtracted[2] === id) {
						if (returnParent) {
							return tree;
						} else {
							return child;
						}
					}
				}
			}
		}

		for (const child of children) {
			const childFound = findElementInTreeByIdRecursive(id, child, returnParent);
			if (childFound) {
				ret = childFound;
			}
		}

		return ret;
	}

	/**
	 * Remove an element from the tree
	 * @public
	 * @param id
	 */
	function removeElementFromTree(id: string) {
		const treeDesign = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		if (treeDesign?.tree) {
			removeElementFromTreeRecursive(id, <ElTreeClass>treeDesign.tree);
		}
	}

	/**
	 * Remove an element from the tree
	 * @private
	 * @param elementIdToRemove
	 * @param tree
	 * @returns
	 */
	function removeElementFromTreeRecursive(elementIdToRemove: string, tree: ElTreeClass|ElTreeItem) {
		let children: ElTreeItem[];
		if (isElTreeClass(tree)) {
			children = tree.elements;
		} else {
			children = tree.children;
		}

		for (const [index, child] of children.entries()) {
			if (child.id === elementIdToRemove) {
				children = children.splice(index, 1);

				return;
			}
		}

		for (const child of children) {
			removeElementFromTreeRecursive(elementIdToRemove, child);
		}

		return;
	}

	/**
	 * Typeguard to check if it's an ElTreeClass
	 * @private
	 * @param obj
	 */
	function isElTreeClass(obj: ElTreeClass | object | undefined): obj is ElTreeClass {
		return obj !== undefined && 'elements' in obj;
	}

	/**
	 * Typeguard to check if it's an ElTreeItem
	 * @private
	 * @param obj
	 */
	function isElTreeItem(obj: ElTreeItem | object | undefined): obj is ElTreeItem {
		return obj !== undefined && 'children' in obj;
	}

	/**
	 * Build the tree designs, based an the baseTree
	 * @private
	 * @param tree
	 * @param design
	 */
	// eslint-disable-next-line complexity
	function buildTreeDesign(tree: ComponentTreeItem, design: string) {
		let enbasTree: ElTreeItem| ElTreeClass;
		if ('Hems' === tree['@type']) {
			let showRootAddButton = false;
			if (design.startsWith('addComponent')) {
				showRootAddButton = true;
			}
			if (design == 'addComponentheatstorage') {
				showRootAddButton = false;
			}
			enbasTree = new ElTreeClass({
				icon: 'sli-logo',
				title: tree.name,
				description: tree.description,
				color: '#7190A6',
				elements: [],
				showRootAddButton
			});
		} else {
			enbasTree = convertToElTreeItem(tree, design);
		}

		if (!tree.children) return enbasTree;

		for (const child of tree.children) {
			if (isComponentTreeItem(child)) {
				const childElement = buildTreeDesign(child, design);
				if (isElTreeItem(childElement)) {
					if (isElTreeClass(enbasTree)) {
						enbasTree.elements.push(childElement);
					} else {
						enbasTree.children.push(childElement);
					}
				}
			}
		}

		return enbasTree;
	}

	/**
	 * Build up the component tree
	 * @private
	 * @param root
	 */
	async function buildBaseTreeRecursive(root: RequestAbstractComponentInterface) {
		const tree = <ComponentTreeItem> {
			'@id': root['@id'],
			'@type': root['@type'],
			name: root.name,
			description: root.description? root.description: '',
			children: []
		};

		if (!root['@id']) return;
		const backendWrapper = new useBackendWrapper<RequestAbstractComponentInterface>(root['@id']);
		const { data: requestGetData } = await backendWrapper.get() as { data: RequestAbstractComponentInterface, error: RequestError };
		let children: RequestAbstractComponentInterface[] | undefined | null;
		if (requestGetData.components) {
			children = root.components;
		} else if (requestGetData.children) {
			children = root.children;
		}

		if (!children) return tree;

		for (const child of children) {
			const childElement = await buildBaseTreeRecursive(child);
			tree.children.push( childElement );
		}

		return tree;
	}

	/**
	 * Covert a component to an ElTreeItem, by using a design
	 * @private
	 * @param component
	 * @param design
	 */
	// eslint-disable-next-line complexity
	function convertToElTreeItem(component: ComponentTreeItem, design: string) {
		let showAddButton = false;
		let icon = 'sli-control-monitor';
		let collapsible = true;
		if (design.startsWith('addComponent')) {
			showAddButton = true;
			collapsible = false;
		}

		let color = '#7190A6';
		switch (component['@type']) {
			case 'PhotovoltaicSystem':
				color = '#D8A200';
				icon = 'sli-solar';
				break;
			case 'ChargePoint':
				color = '#42A496';
				icon = 'sli-wallbox';
				break;
			case 'HeatPump':
				color = '#8A3F55';
				icon = 'sli-fire';
				break;
			case 'Battery':
				color = '#3A6EA1';
				icon = 'sli-battery-voll';
				break;
			case 'BufferStorage':
				color = '#3A6EA1';
				icon = 'sli-pufferspeicher';
				break;
			case 'HeatStorage':
				color = '#3A6EA1';
				icon = 'sli-pufferspeicher';
				break;
			case 'Meter':
				color = '#97BA7D';
				icon = 'sli-zaehler';
				break;
			case 'Hems':
				color = '#7190A6';
				icon = 'sli-logo';
				break;
			default:
				console.error('componentTreeStore convertToElTreeItem(): componentType not implemented:', component['@type']);

		}

		let disabled = false;
		switch (design) {
			case 'default':
				break;
			case 'addComponentphotovoltaic':
				disabled = true;
				break;
			case 'addComponentheatpump':
				disabled = true;
				if (['HeatStorage', 'Meter'].includes(component['@type'])) {
					disabled = false;
				}
				break;
			case 'addComponentmeters':
				disabled = true;
				if (['Battery', 'PhotovoltaicSystem', 'HeatPump', 'ChargingPoint', 'Meter'].includes(component['@type'])) {
					disabled = false;
				}
				break;
			case 'addComponentchargePoint':
				disabled = true;
				if (['Meter'].includes(component['@type'])) {
					disabled = false;
				}
				break;
			case 'addComponentheatstorage':
				disabled = true;
				if (['HeatPump'].includes(component['@type'])) {
					disabled = false;
				}
				break;
			case 'addComponentbatterystorage':
				disabled = true;
				break;
			default:
				console.error('componentTreeStore convertToElTreeItem(): design not implemented:', design);
		}

		return new ElTreeItem({ color, icon, title: component.name, description: component.description? component.description: '', id: component['@id'], children: [], open: true, collapsible, showAddButton, disabled });
	}

	/**
	 * Add an element to the tree
	 * @public
	 * @param elementType
	 * @param whereToAdd
	 * @param design
	 * @param elementToAdd
	 */
	// eslint-disable-next-line complexity
	function addElement(elementType?: string, whereToAdd?: ElTreeItem, design = 'default', elementToAdd?: ElTreeItem) {
		cleanupTree();
		selectedParentId.value = rootId.value;
		let type: string;
		switch (elementType) {
			case 'photovoltaic':
				type = 'PhotovoltaicSystem';
				break;
			case 'chargePoint':
				type = 'ChargePoint';
				break;
			case 'heatpump':
				type = 'HeatPump';
				break;
			case 'batterystorage':
				type = 'Battery';
				break;
			case 'heatstorage':
				type = 'HeatStorage';
				break;
			case 'meters':
				type = 'Meter';
				break;
			default:
				console.error('componentTreeStore addElement(): elementType not implemented:', elementType);

				return;
		}

		if (!elementToAdd) {
			elementToAdd = convertToElTreeItem(<ComponentTreeItem>{
				name: $gettext('COMPONENT_TREE_STORE_NEW_COMPONENT'),
				description: '',
				'@id': 'new',
				'@type': type
			}, design);
		}

		const treeDesignDefault = treeDesigns.value.find((element) => {
			return 'default' === element?.design;
		});
		if (treeDesignDefault) {
			addElementToTreeRecursive(elementToAdd, <ElTreeClass>treeDesignDefault.tree, whereToAdd);
		}
	}

	/**
	 * Add an element to the tree, using recursion
	 * @private
	 * @param elementToAdd
	 * @param tree
	 * @param elementWhereToAdd
	 */
	// eslint-disable-next-line complexity
	function addElementToTreeRecursive(elementToAdd: ElTreeItem, tree:ElTreeClass|ElTreeItem, elementWhereToAdd?: ElTreeItem){
		if (!elementWhereToAdd) {
			if (isElTreeClass(tree)) {
				tree.elements.push(elementToAdd);
			} else {
				tree.children.push(elementToAdd);
			}

			return;
		}

		let children: ElTreeItem[];
		if (isElTreeClass(tree)) {
			children = tree.elements;
		} else {
			children = tree.children;
		}

		for (const child of children) {
			if (elementWhereToAdd.id === child.id) {
				if (isElTreeClass(child)) {
					child.elements.push(elementToAdd);
				} else {
					child.children.push(elementToAdd);
					selectedParentId.value = child.id;
				}

				return;
			}
		}

		for (const child of children) {
			addElementToTreeRecursive(elementToAdd, child, elementWhereToAdd);
		}

		return;
	}

	/**
	 * Update an existing element in all tree designs
	 * @public
	 * @param id
	 * @param updatedElement
	 */
	function updateElementInTree(id: string, updatedElement: ElTreeItem, option?: string) {
		for (const treeDesign of treeDesigns.value) {
			if (treeDesign?.tree) {
				updateElementInTreeRecursive(id, updatedElement, <ElTreeClass>treeDesign.tree, option);
			}
		}
	}

	/**
	* Recursively update an existing element in the tree
	* @private
	* @param idToUpdate
	* @param updatedElement
	* @param tree
	*/
	function updateElementInTreeRecursive(idToUpdate: string, updatedElement: ElTreeItem, tree: ElTreeClass | ElTreeItem, option?: string) {
		let children: ElTreeItem[];
		if (isElTreeClass(tree)) {
			children = tree.elements;
			if (option==='hems'){
				tree.color = updatedElement.color;
				tree.icon = updatedElement.icon;
				tree.title = updatedElement.title;
				tree.description = updatedElement.description;
			}
		} else {
			children = tree.children;
		}

		// Find the child with the matching ID
		for (const child of children) {
			if (child.id === idToUpdate) {
				child.color = updatedElement.color;
				child.icon = updatedElement.icon;
				child.title = updatedElement.title;
				child.description = updatedElement.description;

				return;
			}
		}

		// If we haven't found the child yet, continue searching recursively
		for (const child of children) {
			updateElementInTreeRecursive(idToUpdate, updatedElement, child);
		}
	}

	/**
	 * Returns true if the selectedParentId is the rootId
	 */
	function selectedParentIdIsRoot() {

		const parentItem = findElementInTreeById(activeItemId.value?activeItemId.value:'new', true);

		return isElTreeClass(parentItem);
	}

	/**
	 * Update the activeItemId, and it's selectedParentId
	 * @param id
	 */
	function setActiveItemId(id: string) {
		activeItemId.value = id;
		selectedParentId.value = rootId.value;
		const parentItem = findElementInTreeById(activeItemId.value?activeItemId.value:'new', true);
		if (parentItem && !isElTreeClass(parentItem)) {
			selectedParentId.value = parentItem.id;
		}
	}

	return {
		load,
		cleanupTree,
		removeElementFromTree,
		addElement,
		updateElementInTree,
		findElementInTreeById,
		convertToElTreeItem,
		selectedParentId,
		treeDesigns,
		activeItemId,
		selectedParentIdIsRoot,
		setActiveItemId
	};
});
