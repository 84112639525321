import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type {
	RequestAnalyticsGraphDataInterface,
	RequestAnalyticsTileChargingInfrastructureInterface,
	RequestAnalyticsTilePowerProductionInterface,
	RequestAnalyticsTileOwnConsumptionInterface,
	AnalyticsGraphDataInterface,
	QueryParameter
} from '@/type';
import type { ElSelectOptionType } from '@energielenker/common-component-bundle';
import { useGettext } from 'vue3-gettext';

export const useAnalyticsStore = defineStore('analytics', () => {
	const { $gettext } = useGettext();
	const selectedTimePeriod = ref<ElSelectOptionType>({ id: 'last7d', label: $gettext('ANALYTICS_DROPDOWN_LAST_7D') });
	const selectedTimeAverage = ref<ElSelectOptionType>({ id: 'entireTimePeriod', label: $gettext('ANALYTICS_DROPDOWN_ENTIRE_TIME_PERIOD') },);
	const ownConsumptionCurrent = ref<number>(0.0);
	const ownConsumptionAverage = ref<number>(0.0);
	const powerProductionCurrent = ref<number>(0.0);
	const powerProductionAverage = ref<number>(0.0);
	const chargingInfrastructureCurrent = ref<number>(0.0);
	const chargingInfrastructureAverage = ref<number>(0.0);
	const graphData = ref<AnalyticsGraphDataInterface>();

	/**
	 * Computed var to generate query parameter for some request to backend
	 */
	const queryParameter = computed(() => {
		const queryParameterRaw: QueryParameter = {};

		if (selectedTimePeriod.value) {
			queryParameterRaw['timePeriod'] = selectedTimePeriod.value.id;
		}

		if (selectedTimeAverage.value) {
			queryParameterRaw['timeAverage'] = selectedTimeAverage.value.id;
		}

		return queryParameterRaw;
	});

	async function refreshTiles() {
		await refreshTileOwnConsumption();
		await refreshTilePowerProduction();
		await refreshTileChargingInfrastructure();
		await getGraphData();
	}

	async function refreshTileOwnConsumption() {
		const backendWrapper = new useBackendWrapper<RequestAnalyticsTileOwnConsumptionInterface>('api/analytics/ownConsumption');
		const { data: requestTileValues, error: requestError } = await backendWrapper.get(undefined, queryParameter.value);
		if (requestError) {
			throw new Error('Unable to fetch tiles');
		}
		if (requestTileValues) {
			ownConsumptionCurrent.value = requestTileValues.data.ownConsumption.ownConsumptionCurrent;
			ownConsumptionAverage.value = requestTileValues.data.ownConsumption.ownConsumptionAverage;
		}
	}

	async function refreshTilePowerProduction() {
		const backendWrapper = new useBackendWrapper<RequestAnalyticsTilePowerProductionInterface>('api/analytics/powerConsumption');
		const { data: requestTileValues, error: requestError } = await backendWrapper.get(undefined, queryParameter.value);
		if (requestError) {
			throw new Error('Unable to fetch tiles');
		}
		if (requestTileValues) {
			powerProductionCurrent.value = requestTileValues.data.powerProduction.powerProductionCurrent;
			powerProductionAverage.value = requestTileValues.data.powerProduction.powerProductionAverage;
		}
	}

	async function refreshTileChargingInfrastructure() {
		const backendWrapper = new useBackendWrapper<RequestAnalyticsTileChargingInfrastructureInterface>('api/analytics/chargingInfrastructure');
		const { data: requestTileValues, error: requestError } = await backendWrapper.get(undefined, queryParameter.value);
		if (requestError) {
			throw new Error('Unable to fetch tiles');
		}
		if (requestTileValues) {
			chargingInfrastructureCurrent.value = requestTileValues.data.chargingInfrastructure.chargingInfrastructureCurrent;
			chargingInfrastructureAverage.value = requestTileValues.data.chargingInfrastructure.chargingInfrastructureCurrent;
		}
	}
	function generateMockData(startDate = new Date()) {
		const startTime = new Date(startDate.setHours(0, 0, 0, 0));
		const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000);
		const data: Record<number, number> = {};

		for (let time = startTime; time < endTime; time.setHours(time.getHours() + 1)) {
			const timestamp = Math.floor(time.getTime() / 1000);
			data[timestamp] = Math.random() * 4 - 2;
		}

		return data;
	}
	async function getGraphData() {
		const backendApi = new useBackendWrapper<RequestAnalyticsGraphDataInterface>('api/analytics/graph');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		// TODO: Remove mocked data when backend is ready
		graphData.value = {
			graph_energy: {
				electricityTimeSeries: generateMockData(),
				timestampPredictionStart: 1,
				isStatic: true
			}
		}
		if (requestGetError) {
			throw new Error('Unable to fetch graph data.');
		}
		if (requestGetData) {
			if (requestGetData.data) {
				graphData.value = requestGetData.data;
			}
		}

	}

	return {
		selectedTimePeriod,
		selectedTimeAverage,
		ownConsumptionCurrent,
		ownConsumptionAverage,
		powerProductionCurrent,
		powerProductionAverage,
		chargingInfrastructureCurrent,
		chargingInfrastructureAverage,
		graphData,
		getGraphData,
		refreshTiles
	};
});
