import { isProxy } from 'vue';
import deepToRaw from '@/utils/function/deepToRaw';

/**
 * Function which creates a deep copy of an object
 *
 * @param obj
 */
export default function deepCopy<T>(obj: T): T {
	// Use new structuredClone() function if available
	if ('function' === typeof structuredClone) {
		// You can't clone Vue's proxy
		if (isProxy(obj)) {
			return structuredClone(deepToRaw(obj));
		}

		return structuredClone(obj);
	} else {
		// Workaround by screwing around with JSON stringify -> parse
		return JSON.parse(JSON.stringify(obj)) as T;
	}
}
