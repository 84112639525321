import { isProxy, toRaw } from 'vue';

/**
 * Function which makes a vue proxy object raw and all of his child ojects too.
 * @param obj
 */
export default function deepToRaw<T>(obj: T): T {
	const rawObj = toRaw(obj);
	for (const key in rawObj) {
		if (isProxy(rawObj[key])) {
			rawObj[key] = deepToRaw(rawObj[key]);
		}
	}

	return rawObj;
}
