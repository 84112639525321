import * as Sentry from '@sentry/vue';
type statusType = {
	code: number;
	text: string;
};

/**
 * Class representing an error happened while requesting an API resource.
 */
export default class RequestError extends Error {
	protected url: string;
	protected body: string;
	protected status: statusType;
	protected headers?: Headers;

	/**
	 * RequestError
	 *
	 * @param message	Message of error
	 * @param url		Url of response
	 * @param status	HTTP Status code and text of response
	 * @param body		Body of response as a string
	 * @param headers	Headers of response
	 */
	constructor(message: string, url: string, status: statusType, body: string = '', headers?: Headers) {
		super(message);
		Object.setPrototypeOf(this, RequestError.prototype);

		this.url = url;
		this.status = status;
		this.body = body;
		this.headers = headers;

		if (!import.meta.env.DEV){
			Sentry.captureException(this);
		}
	}

	/**
	 * Returns url of Response
	 */
	getUrl() {
		return this.url;
	}

	/**
	 * Returns HTTP Status code of Response
	 */
	getStatusCode() {
		return this.status.code;
	}

	/**
	 * Returns HTTP Status text of response
	 */
	getStatusText() {
		return this.status.text;
	}

	/**
	 * Returns body of the response as a string
	 */
	getBody() {
		return this.body;
	}

	/**
	 * Returns the body parsed as a JSON string
	 */
	getBodyParsedAsJSON<Type>(): Type {
		return JSON.parse(this.body) as Type;
	}

	/**
	 * Return headers of response
	 */
	getHeaders() {
		return this.headers;
	}

	/**
	 * Create a RequestError from a Response object and with a arbitrary message
	 *
	 * @param message
	 * @param response
	 */
	static async createFromResponse(message: string, response: Response): Promise<RequestError> {
		const body = await response.text();

		const error = new RequestError(
			message,
			response.url,
			{
				code: response.status,
				text: response.statusText,
			},
			body,
			response.headers
		);
		if (!import.meta.env.DEV){
			Sentry.captureException(error);
		}

		return error;
	}
}
