import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type { LicenseDataInterface } from '@/type';

export const useLicenseStore = defineStore('license', () => {

	const licensesData = ref<LicenseDataInterface>();

	async function loadLicenses() {
		const backendApi = new useBackendWrapper<LicenseDataInterface>('api/lmb/license');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error('Unable to fetch license data.');
		}
		if (requestGetData) {
			licensesData.value = requestGetData;
		}
	}

	async function uploadLicense(licenseFile: File) {
		const backendApi = new useBackendWrapper('api/lmb/license/upload');

		const formData = new FormData();
		formData.append('file', licenseFile);

		const { data: requestPostData, error: requestPostError } = await backendApi.post(formData);

		if (requestPostError) {
			throw new Error('Unable to upload license file.');
		}

		return requestPostData;
	}

	return {
		licensesData,
		loadLicenses,
		uploadLicense
	};
});
