import type { UserJwtInterface } from '@/type/interface';
import type RequestRefreshJwtInterface from '@/type/interface/Request/RequestRefreshJwtInterface';
import { defineStore, storeToRefs } from 'pinia';
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import { computed, readonly, ref, watch, watchEffect } from 'vue';
import useRefreshTokenStore from './refreshToken';
import { default as useUserStore } from '@/stores/user';
import { useSystemTime } from '@/stores/index';

const TOKEN_REFRESH_WAIT_TIME = import.meta.env.VITE_TOKEN_REFRESH_WAIT_TIME;
const storageKey = 'ElEnbasUserToken';

/**
 * @description: this store handles JWT token, needed for authentication
 */
const useJwtStore= defineStore('jwt',() => {

	const refreshTokenStore = useRefreshTokenStore();
	const { isValid: refreshTokenIsValid, refreshToken } = storeToRefs(refreshTokenStore);
	const { timestamp } = storeToRefs(useSystemTime());
	const tokenEncoded = ref<string>();
	const renewing = ref(false);
	let timeout: ReturnType<typeof window.setTimeout> | null = null;

	/**
	 * if a valid token is stored, update session storage with it. if not, remove the token from session storage
	 */
	const token = computed(() => {
		if (tokenEncoded.value === undefined|| tokenEncoded.value === null) {
			return undefined;
		}

		try {
			const tokenAsString = window.atob(tokenEncoded.value.split('.')[1]);

			return JSON.parse(tokenAsString) as UserJwtInterface;
		} catch (e) {
			// If parsing failed remove encoded data
			console.error('[JWT] Error while decoding token', e);

			return undefined;
		}
	});

	/**
	 * Determines if the token is still valid. If the time in minutes from token expiration to TOKEN_REFRESH_WAIT_TIME
	 * is less than TOKEN_REFRESH_WAIT_TIME, the token is considered invalid.
	 *
	 * @public
	 * @returns boolean
	 */
	const isValid = computed(() => {

		if (token.value === undefined && timestamp.value === 0) {
			return undefined;
		} else if (token.value === undefined) {
			return false;
		}
		const now = new Date(timestamp.value * 1000);
		const expires = new Date(token.value.exp * 1000);
		const diff = expires.getTime() - now.getTime();
		const minutes = Math.floor(diff / 60000);

		return minutes >= 0 && minutes < TOKEN_REFRESH_WAIT_TIME;
	})

	/*
	if a token is invalid, remove it
	 */
	watch(isValid,(isValid) => {
		if (isValid === false) {
			removeToken();
		}
	});

	/**
	 * if we dont have the systemTime loaded set renewing to true, so we
	 * will not get a login screen
	 */
	watch(timestamp, (newTimestamp, oldTimestamp) => {
		if (newTimestamp === 0) {
			renewing.value = true;
		} else if (oldTimestamp === 0){
			renewing.value = false;
		}
	}, { immediate: true });

	watch(refreshTokenIsValid, (refreshTokenIsValid) => {
		if (refreshTokenIsValid === undefined) return;

		const jwtTokenRaw = sessionStorage.getItem(storageKey);

		if (jwtTokenRaw === null && refreshTokenIsValid){
			void renew();

			return;
		}

		try {
			setTokenPayload(jwtTokenRaw!);
		} catch (e) {
			console.error('[JWT] Error while loading token from storage', e);
		}

	})

	// if token or isValid change, we may want to remove set token, else refresh timeout
	watchEffect( () => {
		// Always clear previous timeout
		clearRefreshTimeout();

		// Don't do anything if token has been deleted, and isValid is not defined
		if (token.value === undefined && isValid.value === undefined) {
			return;
		}

		if (isValid.value === false) {
			tokenEncoded.value = undefined;

			return;
		}

		if (token.value) {
			setRefreshTimeout(token.value);
		}
	});

	/**
	 * Set token into store. Token has to be encoded one from API login Response
	 * @public
	 * @param encodedToken
	 */
	function setTokenPayload(encodedToken: string): void {
		tokenEncoded.value = encodedToken;
		sessionStorage.setItem(storageKey, tokenEncoded.value);
	}

	/**
	 * Deletes the stored JWT
	 * @public
	 */
	function removeToken(): void {
		sessionStorage.removeItem(storageKey);
		tokenEncoded.value = undefined;
	}

	/**
	 * Requesting a new JWT token from backend
	 *
	 * @public
	 */
	async function renew() {
		if (!refreshTokenIsValid.value || refreshToken.value === undefined) {
			return;
		}

		renewing.value = true;
		const backendWrapperAuth = new useBackendWrapper<RequestRefreshJwtInterface>(
			'token/refresh',
			false
		);
		const {
			error: requestError,
			data: requestData
		} = await backendWrapperAuth.post({ refresh_token: refreshToken.value.token });

		if (requestError || !requestData) {
			console.error('[JWT] Error getting new token ', requestError, requestData, refreshToken.value.token);
			renewing.value = false;
			const userStore = useUserStore();
			userStore.logout();

			return;
		}
		setTokenPayload(requestData.token as string);
		refreshTokenStore.setToken(requestData.refresh_token, requestData.rte as number);
		renewing.value = false;
	}

	/**
	 * Adds JWT to headers
	 * @public
	 * @param headers
	 * @returns Headers
	 */
	function addAuthorizationHeader(headers: Headers) {
		if (undefined === tokenEncoded.value) {
			return;
		}

		headers.append('Authorization', 'Bearer ' + tokenEncoded.value);

		return headers;
	}

	/**
	 * Sets a timeout to get a new JWT
	 *
	 * @private
	 * @param token
	 */
	function setRefreshTimeout(token: UserJwtInterface) {
		const time = Math.ceil(token.exp - (Date.now() / 1000) - 3);

		clearRefreshTimeout();

		if (time <= 0) {
			if (import.meta.env.DEV && !import.meta.env.TEST) {
				console.warn('[JWT] timeout is negative');
			}

			return;
		}

		timeout = setTimeout(() => {
			void renew();
		}, time * 1000);
	}

	/**
	 * Clears a previously set timeout to refresh JWT
	 *
	 * @private
	 */
	function clearRefreshTimeout() {
		if (timeout !== null) {
			window.clearTimeout(timeout);
			timeout = null;
		}
	}

	return {
		token,
		isRenewing: readonly(renewing),
		setTokenPayload,
		removeToken,
		addAuthorizationHeader,
		isValid,
		renew
	};

});

export default useJwtStore;
