<template>
	<div class="footer">
		<span class="version">{{ version }}</span>
		<div
			v-if="usedImageTags.length > 0"
			class="used-tags"
		>
			<table class="table table-sm">
				<tbody
					v-for="(imageTags) in usedImageTags"
					:key="imageTags[0]"
				>
					<tr>
						<th>{{ imageTags[0] }}</th>
						<td>{{ imageTags[1] }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue';

const version = import.meta.env.ENBAS_VERSION;
const loadUsedTagsFile = import.meta.env.VITE_LOAD_USED_TAGS_FILE === '1' || import.meta.env.VITE_LOAD_USED_TAGS_FILE === 'true';

type UsedImageTags = Array<[string, string]>;

const usedImageTags = ref([] as UsedImageTags);

onMounted(async () => {
	usedImageTags.value = [];

	if (loadUsedTagsFile) {
		const res = await fetch('/used_tags');
		const usedTagsRaw = await res.text();

		const imageTags = usedTagsRaw.split('\n');
		for (let i = 0; i < imageTags.length; i++) {
			const imageTag = imageTags[i].trim();
			const parts = imageTag.split(':');

			if (parts.length == 2) {
				usedImageTags.value.push([
					parts[0].trim(),
					parts[1].trim()
				]);
			}
		}
	}
});

</script>

<style scoped lang="scss">
// The default font size for html is 16px
$base-size: 16;

// Function to convert px value into rem
@function rem($target, $context: $base-size) {
	@return calc($target / $context) * 1rem;
}

.footer{
	font-size: rem(13);
	text-align: center;
	position: absolute;
	line-height: 2;
	color: var(--color-primary-midrange-grey);
	bottom: 0;
	left: 0;
	right: 0;
	height: rem(100);

	.used-tags {
		display: none;
		background-color: #002A48;
		color: #ffffff;
		position: absolute;
		left: 0;
		right: 0;
		margin-left: auto;
		margin-right: auto;
		margin-top: -100%;
		max-width: 75%;

		td, th {
			text-align: left;
		}
		td {
			text-wrap: pretty;
		}
	}

	&:hover .used-tags {
		display: block;
	}
}
</style>
