<template>
	<div
		class="brand-wrap mb-4 w-75"
		:class="{'shrink': shrinkImg}"
	>
		<div class="img-wrap">
			<img
				src="/el/logo.png"
				alt="Logo"
			>
			<div
				v-if="banner != ''"
				class="logo-banner"
			>
				{{ banner }}
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
const banner = import.meta.env.VITE_BANNER;
// const banner = 'beta';
defineProps({
	shrinkImg: {
		type: Boolean,
		default: false
	}
});
</script>

<style scoped lang="scss">
.brand-wrap {
	max-width: 144px;
	padding: 0.5rem;

	.img-wrap {
		display: inline-block;
		position: relative;
	}

	img {
		height: auto;
		padding: 0.563rem 0 0.563rem 1.063rem;
	}

	&.shrink img {
		width: 100%;
	}

	.logo-banner {
		top: 50%;
		margin-top: .5rem;
		right: 0;
		position: absolute;
		text-align: center;
		color: var(--color-secondary-red-full);
		font-weight: bold;
		rotate: -23deg;
	}
}
</style>
