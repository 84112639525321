import { ref, watchEffect } from 'vue';
import gettext from '@/gettext';
import type { ElHeaderLanguageSelectLanguageType } from '@energielenker/common-component-bundle';
import moment from 'moment-timezone';
const languageKey = 'ElEnbasAppLanguage';
const languageSelected = ref<ElHeaderLanguageSelectLanguageType | undefined>();
const language = ref(localStorage.getItem(languageKey) ?? 'de');
export function useLanguage() {

	watchEffect(() => {
		localStorage.setItem(languageKey, language.value);
	});

	const setLanguage = (lang: string ) => {
		language.value = lang;
		gettext.current = lang;
	};

	const dateformatList: Record<string, { code: string, format: string }> = {
		en: {
			code: 'en-US',
			format: 'MM.DD.YYYY'
		},
		de: {
			code: 'de-DE',
			format: 'DD.MM.YYYY'
		},
	}

	const formatDate = (date: string | Date) => {
		const locale = dateformatList[language.value].code;

		return new Date(date).toLocaleDateString(locale).replace(/\//g, '.');
	};

	const formatDateTime = (date: string | Date) => {
		const locale = dateformatList[language.value].code;
		const options: Intl.DateTimeFormatOptions = {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
			hour: '2-digit',
			minute: '2-digit',
			hour12: false
		};

		return new Date(date).toLocaleString(locale, options).replace(',', '');
	};

	const getLocalDate = (date: string | Date) => {
		const localDate = moment(date).local();

		return localDate.format('YYYY-MM-DD HH:mm')
	}

	const getTime = (date: string | Date) => {
		return new Date(date).getTime()
	}

	const getTimezone = () => {
		return moment.tz.guess()
	}

	let languages: ElHeaderLanguageSelectLanguageType[] = [];
	const gettextLanguages = gettext.available;

	for (const [key] of Object.entries(gettextLanguages)) {
		languages = [...languages, { id: key, caption: key.toUpperCase() } as ElHeaderLanguageSelectLanguageType];
	}

	languageSelected.value = languages.find((element) => {
		return element.id === gettext.current
	});

	const handleLanguageChange = (event: ElHeaderLanguageSelectLanguageType) => {
		setLanguage(<string>event.id);
	};

	return {
		language,
		setLanguage,
		formatDate,
		formatDateTime,
		languages,
		languageSelected,
		handleLanguageChange,
		getLocalDate,
		getTime,
		getTimezone
	};
}
