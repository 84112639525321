import { type ArrayElementType, FetchMethod, type FetchMethodType } from '@/type';
import { default as RequestError } from './RequestError';

let urlBackend = import.meta.env.VITE_EL_BACKEND;
const useDummyAPI = 'true' === import.meta.env.VITE_EL_USE_DUMMY_API || '1' === import.meta.env.VITE_EL_USE_DUMMY_API;
type BodyType<T> = T | BodyInit;

/**
 * Checks if body of response has json by headers
 *
 * @param headers
 */
function checkContentTypeIsJsonByHeader(headers: Headers): boolean {
	if (headers.has('content-type')) {
		const contentType = headers.get('content-type') as string;

		return contentType.indexOf('application/ld+json') !== -1 || contentType.indexOf('application/json') !== -1;
	}

	return false;
}

/**
 * Generic class of a request for a backend API resource
 */
export default class Request<Type = unknown> {
	protected headers: Headers;
	protected body: BodyType<Type> | null = null;
	protected expectedResultCode = [200, 201, 202, 204];

	/**
	 * Generic Request class.
	 *
	 * @param url		Can either be an absolut URL or a relative one. If relative, base URL defined through
	 * 					VITE_EL_BACKEND environment variable is prepended.
	 * @param method	Method of HTTP Request
	 * @param headers	Additional headers for the request
	 */
	constructor(
		protected url: string,
		protected method: FetchMethodType = 'GET',
		headers: Headers | null = null
	) {
		if (null === headers) {
			this.headers = new Headers();
		} else {
			this.headers = headers;
		}
	}

	/**
	 * Add mandatory headers to the request
	 *
	 * @protected
	 */
	protected setMandatoryHeaders() {
		// Allow compression
		this.headers.append('Accept-Encoding', 'deflate');
		this.headers.append('Accept-Encoding', 'gzip');
	}

	/**
	 * Validate class member. Throws errors if there are some
	 *
	 * @protected
	 */
	protected validate(this: Request<Type>) {
		if (0 === this.url.length) {
			throw new Error('Empty URL');
		}

		if (!FetchMethod.includes(this.method)) {
			throw new Error(`Unsupported Method ${this.method} given`);
		}

		if (!this.url.startsWith('http') && (urlBackend === undefined || 0 === urlBackend.length)) {

			if (import.meta.env.DEV) {

				urlBackend = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/api/';
			} else {
				throw new Error('No backend URL defined');
			}
		}
	}

	/**
	 * Returns the target url of the API resource.
	 * Throws an error if url for backend wasn't provided through constructor
	 *
	 * @protected
	 */
	protected getUrl(this: Request<Type>) {
		if (this.url.startsWith('http')) {
			return this.url;
		}

		if (urlBackend === undefined || 0 === urlBackend.length) {
			throw new Error('No backend URL defined');
		}

		let url = `${urlBackend}${this.url}`;
		url = url.replace('/api//api','/api');
		url = url.replace('/api/api','/api');

		if (useDummyAPI) {
			switch (this.method) {
				case 'GET':
					url = `${url}.json`;
					break;
			}
		}

		return url;
	}

	/**
	 * Sets the body which will be sent to API backend
	 *
	 * @param body
	 */
	setBody(this: Request<Type>, body: BodyType<Type>) {
		if (['GET', 'HEAD'].includes(this.method)) {
			throw new Error(`Can't use body with ${this.method.toLowerCase()} method`);
		}
		this.body = body;

		return this;
	}

	/**
	 * Sets expected HTTP status codes returned from API. If you expect something different from a 200 you might
	 * need this function.
	 *
	 * @param expectedResultCode	One or multiple expected status code
	 */
	setExpectedResultHTTPCode(
		this: Request<Type>,
		expectedResultCode: typeof this.expectedResultCode | ArrayElementType<typeof this.expectedResultCode>
	) {
		if ('number' === typeof expectedResultCode) {
			this.expectedResultCode = [expectedResultCode];
		} else {
			this.expectedResultCode = expectedResultCode;
		}

		return this;
	}

	/**
	 * Execute the request. Before execution parameters of request will be checked for validity.
	 *
	 * @throws RequestError | Error
	 */
	exec(this: Request<Type>) : Promise<Type | string> {
		this.validate();
		this.setMandatoryHeaders();

		if (useDummyAPI && 'GET' !== this.method) {
			console.warn('useDummyAPI - not sending '+ this.method +' request: ' + this.getUrl(), this);

			return new Promise((resolve) => {
				setTimeout(() => {
					return resolve(<Type>{ message: 'success' });
				}, 1);
			});
		}

		const options: RequestInit = {
			method: this.method,
			headers: this.headers
		};

		// Append body to fetch options
		if (this.body !== null) {
			if (this.body instanceof FormData) {
				options.body = this.body
			} else {
				options.body = JSON.stringify(this.body);
			}

		}

		return fetch(
			this.getUrl(),
			options
		).then(async (response) => {
			// Unexpected return status code
			if (!this.expectedResultCode.includes(response.status)) {
				throw await RequestError.createFromResponse('unexpected status code received', response);
			}

			if (checkContentTypeIsJsonByHeader(response.headers)) {
				return await response.json() as Type;
			}

			return await response.text();
		}).then((data) => {
			if (useDummyAPI) {
				return new Promise<Type | string>((resolve) => {
					setTimeout(() => {
						resolve(data);
					}, 250);
				});
			}

			return data;
		}).catch((reason) => {
			if (!(reason instanceof RequestError) && !(reason instanceof Error)) {
				if ('string' === typeof reason) {
					reason = new Error(reason);
				} else {
					console.error(reason);
					reason = new Error('Unexpected Error');
				}
			}

			throw reason;
		});
	}
}
