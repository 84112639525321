import { defineStore } from 'pinia';
import { ref } from 'vue';
import gettext from '@/gettext'
import { useBackendWrapper } from '@/composable/request/useBackendWrapper';
import type {
	OptimizationAbsenceDataInterface,
	OptimizationGraphDataInterface,
	RequestOptimizationGraphDataInterface,
	FullRequiredRequestChargingPointCollectionInterface,
	PatchRequestChargePointInterface,
	FullRequiredRequestChargePointInterface,
	FullRequiredRequestHeatStorageInterface,
	FullRequiredRequestHeatStorageCollectionInterface, PatchRequestHeatStorageInterface
} from '@/type';
import AbsenceState from '@/type/enum/AbsenceState';

function transformObjectToArray(data: Record<string, number>): { [key: string]: number }[] {
	const transformedData: { [key: string]: number }[] = [];
	for (const key in data) {
		transformedData.push({ [key]: data[key] });
	}

	return transformedData;
}
export const useOptimizationStore = defineStore('optimization', () => {
	const absenceData = ref<OptimizationAbsenceDataInterface>({
		status: AbsenceState.Active,
		startDateTime: undefined,
		endDateTime: undefined,
	});
	const graphData = ref<OptimizationGraphDataInterface>();
	const chargePoints = ref<FullRequiredRequestChargePointInterface[]>()
	const heatStorages = ref<FullRequiredRequestHeatStorageInterface[]>();
	const { $gettext } = gettext;

	async function getGraphData() {

		const backendApi = new useBackendWrapper<RequestOptimizationGraphDataInterface>('api/optimization/schedule');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();

		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_GET_GRAPH_DATA'));
		}
		if (requestGetData) {
			graphData.value = {
				pvForecast: transformObjectToArray(requestGetData.optimization_schedule.pvForecast),
				lobasSchedule: transformObjectToArray(requestGetData.optimization_schedule.lobasSchedule),
				heatPumpsSchedule: transformObjectToArray(requestGetData.optimization_schedule.heatPumpsSchedule),
				electricityPrices: transformObjectToArray(requestGetData.optimization_schedule.electricityPrices),
				startDate: requestGetData.optimization_schedule.startDate,
				endDate: requestGetData.optimization_schedule.endDate
			}
		}

	}
	async function getHeatStorages(){
		const backendApi = new useBackendWrapper<FullRequiredRequestHeatStorageCollectionInterface>('api/heat_storages');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_GET_HEAT_STORAGES'));
		}
		if (requestGetData) {
			heatStorages.value = requestGetData['hydra:member'];
		}
	}
	async function updateHeatStorage(id: string, formData: PatchRequestHeatStorageInterface) {
		const backendApi = new useBackendWrapper<PatchRequestHeatStorageInterface>('api/heat_storages');
		const { data: response, error: requestGetError } = await backendApi.patch(id, formData);
		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_UPDATE_HEAT_STORAGE'));
		}

		if (response) {
			// Update store data with the response
			heatStorages.value = heatStorages.value?.map((heatStorage) => {
				if (heatStorage.id === response.id) {
					heatStorage.optimize = response.optimize;
					heatStorage.temperatureMin = response.temperatureMin;
					heatStorage.temperatureMax = response.temperatureMax;
				}

				return heatStorage;
			})
		}
	}
	async function getChargePoints(){
		const backendApi = new useBackendWrapper<FullRequiredRequestChargingPointCollectionInterface>('api/charge_points');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_GET_CHARGE_POINTS'));
		}
		if (requestGetData) {
			chargePoints.value = requestGetData['hydra:member'];
		}
	}
	async function updateChargePoint(id: string, formData: PatchRequestChargePointInterface) {
		const backendApi = new useBackendWrapper<PatchRequestChargePointInterface>('api/charge_points');
		const { data: requestGetData, error: requestGetError } = await backendApi.patch(id, formData);
		if (requestGetError || !requestGetData) {
			const cpToUpdate = chargePoints.value?.find(({ id: chargePointId }) => {
				return chargePointId === id;
			});
			let name = $gettext('OPTIMIZATION_STORE_UNKNOWN_NAME');
			if (cpToUpdate) {
				name = cpToUpdate.name;
			}

			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_UPDATE_CHARGE_POINT', { name }));
		}

		if (requestGetData) {
			chargePoints.value = chargePoints.value?.map((chargePoint) => {
				if (chargePoint.id === requestGetData.id) {
					chargePoint.optimizationPeriod = requestGetData.optimizationPeriod;
					chargePoint.optimizationQuota = requestGetData.optimizationQuota;
				}

				return chargePoint;
			});
		}
	}

	/**
	 * Set optimization state for all charge points at once.
	 *
	 * This only required temporarily. As soon as backend is able to utilize optimization state for each individual
	 * charge point this can be removed.
	 * @param optimizeState
	 */
	async function updateChargePointsOptimizeState(optimizeState: boolean) {
		const backendApi = new useBackendWrapper<{ optimize: boolean }>('api/charge_points/optimize', true, 'application/json');
		const { data: requestGetData, error: requestGetError } = await backendApi.post({
			optimize: optimizeState,
		});
		if (requestGetError || !requestGetData) {
			let message;
			if (optimizeState) {
				message = $gettext('OPTIMIZATION_STORE_UNABLE_TO_ACTIVATE_OPTIMIZATION_STATE');
			} else {
				message = $gettext('OPTIMIZATION_STORE_UNABLE_TO_DEACTIVATE_OPTIMIZATION_STATE');
			}
			throw new Error(message);
		}
	}
	async function getAbsenceData() {
		const backendApi = new useBackendWrapper<OptimizationAbsenceDataInterface>('api/absence');
		const { data: requestGetData, error: requestGetError } = await backendApi.get();
		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_GET_ABSENCE_GET'));
		}
		absenceData.value = requestGetData!;

	}
	async function postAbsenceData(data: OptimizationAbsenceDataInterface) {
		const backendApi = new useBackendWrapper<OptimizationAbsenceDataInterface>('api/absence');
		const { data: requestGetData, error: requestGetError } = await backendApi.post(data);
		if (requestGetError) {

			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_UPDATE_ABSENCE'));
		}
		if (requestGetData) {
			absenceData.value = requestGetData;
		}

	}

	async function deactivateAbsence() {
		const backendApi = new useBackendWrapper('api/absence');
		const { error: requestGetError } = await backendApi.delete();
		if (requestGetError) {
			throw new Error($gettext('OPTIMIZATION_STORE_UNABLE_TO_DELETE_ABSENCE'));
		}
		absenceData.value = {
			status: AbsenceState.Inactive,
			startDateTime: undefined,
			endDateTime: undefined,
		};
	}

	return {
		absenceData,
		getAbsenceData,
		postAbsenceData,
		deactivateAbsence,
		graphData,
		getGraphData,
		chargePoints,
		getChargePoints,
		updateChargePointsOptimizeState,
		updateChargePoint,
		heatStorages,
		getHeatStorages,
		updateHeatStorage
	}
});

