<template>
	<ElButton
		v-if="!isLoggedIn"
		class="login-button"
		:class="{logoutLoginButtonPostion: !isLoggedIn }"
		:variant="isScreenSizeLargerThan('lg') ? 'primary' : 'transparent'"
		:small="false"
		:large="false"
		:disabled="false"
		@click.prevent="loginUser"
	>
		<span
			v-if="isScreenSizeLargerThan('lg')"
			class="sli sli-logout"
		/>
		{{ $gettext('LOGIN_BUTTON_LABEL') }}
	</ElButton>
	<ElButton
		v-if="isLoggedIn"
		class="logout-button"
		:variant="isScreenSizeLargerThan('lg') ? 'primary' : 'transparent'"
		:small="false"
		:large="false"
		:disabled="false"
		@click.prevent="logoutUser"
	>
		<span
			v-if="isScreenSizeLargerThan('lg')"
			class="sli sli-logout"
		/>
		{{ $gettext('LOGOUT_BUTTON_LABEL') }}
	</ElButton>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import gettext from '@/gettext';
import { useUserStore } from '@/stores';
import { ElRouter } from '@energielenker/common-component-bundle';
import { useScreenSize } from '@/composable/useScreenSize';

const router = ElRouter;
const userStore = useUserStore();
const { $gettext } = gettext;
const loginStateStore = useUserStore();
const { isLoggedIn } = storeToRefs(loginStateStore);

const { isScreenSizeLargerThan } = useScreenSize()
async function logoutUser() {
	await router.push({ name: 'home' });
	userStore.logout();
}

async function loginUser() {
	await router.push({ name: 'login', query: { redirect: router.currentRoute.value.fullPath } });
}

</script>

<style lang="scss">
.sli-logout {
	padding-right: 0.5rem;
}

@include media-breakpoint-down(lg) {
	#app {
		.login-button,.logout-button{
			color: var(--color-red-original) !important;
			padding:8px;
		}
	}
}
</style>
