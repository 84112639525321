import { defineStore, storeToRefs } from 'pinia';
import { computed, ref, toRaw, unref } from 'vue';
import { useSystemTime } from '@/stores/index';
import type { RefreshTokenData } from '@/type';

const storageKey = 'refreshToken';

const useRefreshTokenStore = defineStore('refreshToken', () => {
	const refreshToken = ref<RefreshTokenData | undefined>();

	const { timestamp } = storeToRefs(useSystemTime());

	/**
	 * Checks if token is still valid. Uses a margin of 3 seconds before expiration. So a token is considered invalid if
	 * its actually still valid for 3 seconds.
	 *
	 * If token is considered as invalid it will be removed from store.
	 *
	 * @public
	 */
	const isValid = computed( () => {
		const refreshTokenValue = unref(refreshToken);
		if (refreshTokenValue === undefined ||
			timestamp.value === 0
		) {
			return undefined;
		}

		const thresholdToIssueNewToken = 60; // 60 seconds seems to be way too much but consider slow connections!
		const isValid = refreshTokenValue.expiration - thresholdToIssueNewToken >= timestamp.value;

		// If token is invalid, remove it
		if (!isValid) {
			removeToken();
		}

		return isValid;
	});

	// Load refresh token data from local storage
	if (localStorage.getItem(storageKey) !== null) {
		try {
			refreshToken.value = JSON.parse(localStorage.getItem(storageKey) as string) as RefreshTokenData;
		} catch (e) {
			console.error('[RefreshToken] Error while loading token from storage', e);
			removeToken();
		}
	}

	/**
	 * Set token to the store
	 *
	 * @public
	 * @param token
	 * @param expirationDate
	 */
	function setToken(token: string, expirationDate: number) {

		refreshToken.value = {
			token,
			expiration: expirationDate
		}

		localStorage.setItem(storageKey, JSON.stringify(toRaw(refreshToken)));
	}

	/**
	 * Removes token from the store
	 *
	 * @public
	 */
	function removeToken() {
		refreshToken.value = undefined;
		localStorage.removeItem(storageKey);
	}

	return {
		refreshToken,
		setToken,
		removeToken,
		isValid
	};
});

export default useRefreshTokenStore;
