import { isRef, unref } from 'vue';
import Request from '@/utils/function/Request';
import RequestError from '@/utils/function/RequestError';
import { useJwtStore } from '@/stores';
import type { FetchMethodType, GenericResponseType, GenericSendRequestOptions, QueryParameter, QueryParameterBase } from '@/type';

/**
 * Wrapper class to send requests to api backend, to fetch, store and delete data
 * A typeMap needs to be set in the constructor, to provide which requestInterface belongs to the requested url
 */
export class useBackendWrapper<T> {
	/**
	 * @constructor
	 * @param url
	 * @param useApiAuthorizationHeader
	 * @param acceptedResponseType
	 */
	constructor(
        protected readonly url: string,
		protected readonly useApiAuthorizationHeader: boolean = true,
		protected readonly acceptedResponseType: string = 'application/ld+json'
	) {}

	/**
	 * Fetch a resource from backend by its uuid
	 *
	 * @param uuid
	 * @param queryParameter
	 * @returns {object} {data,error}
	 */
	public async get (uuid?: string, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		return await this.sendRequest({
			method: 'GET',
			uuid,
			queryParameter
		});
	}

	/**
	 * Create a resource in the backend
	 *
	 * @param {object} data
	 * @param queryParameter
	 * @returns {object} {data,error}
	 */
	public async post (data?: T, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		return await this.sendRequest({
			method: 'POST',
			data,
			queryParameter,
			expectedStatusCodes: [200, 201]
		});
	}

	/**
	 * Update a resource in the backend by a given uuid
	 *
	 * @param uuid
	 * @param {object} data
	 * @param queryParameter
	 * @returns {object} {data,error}
	 */
	public async put (uuid?: string, data?: T, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		return await this.sendRequest({
			method: 'PUT',
			uuid,
			data,
			queryParameter
		});
	}

	/**
	 * Update a resource partially in the backend by a given uuid
	 *
	 * @param uuid
	 * @param {object} data
	 * @param queryParameter
	 * @returns {object} {data,error}
	 */
	public async patch(uuid?: string, data?: T, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		return await this.sendRequest({
			method: 'PATCH',
			uuid,
			data,
			queryParameter,
			expectedStatusCodes: [200, 204]
		});
	}

	/**
	 * Create or update a resource in the backend. Works by expecting a 'id' property in given data object. That property
	 * should be a string and not empty for this to work.
	 *
	 * @param data
	 * @param queryParameter
	 */
	public async postOrPut(data: T & { id?: string }, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		if (data.id && data.id.length > 0) {
			return await this.put(data.id, data, queryParameter);
		} else {
			return await this.post(data, queryParameter);
		}
	}

	/**
	 * Delete a resource in the backend by its uuid
	 *
	 * @param uuid
	 * @param queryParameter
	 * @returns {object} {data,error}
	 */
	public async delete (uuid?: string, queryParameter?: QueryParameter): Promise<GenericResponseType<T>> {
		return await this.sendRequest({
			method: 'DELETE',
			uuid,
			queryParameter,
			expectedStatusCodes: 204
		});
	}

	/**
	 * Send request to backend, return data
	 *
	 *
	 * @return {object} {data,error}
	 * @param options
	 */
	// eslint-disable-next-line complexity
	private async sendRequest (
		options: GenericSendRequestOptions<T>
	): Promise<GenericResponseType<T>> {

		const returnValue: GenericResponseType<T> = {
			data: <T | undefined>undefined,
			error: <RequestError | Error | undefined>undefined,
		};

		try {
			const { method, expectedStatusCodes, data } = options;
			const requestUrl = this.prepareUrl(options, method);
			// Prepare header for Request to API Platform backend
			const headers = this.setHeaders(data, method);

			// add the authorization header to the request
			if (this.useApiAuthorizationHeader) {
				const jwtStore = useJwtStore();
				jwtStore.addAuthorizationHeader(headers);
			}

			const request = new Request<T>(
				requestUrl,
				method,
				headers
			);

			if (data && ['POST', 'PUT', 'PATCH'].includes(method)) {
				request.setBody(data);
			}

			if (undefined !== expectedStatusCodes) {
				request.setExpectedResultHTTPCode(expectedStatusCodes);
			}

			const responseData = await request.exec();

			returnValue.data = typeof responseData !== 'string' ? responseData : undefined;
		} catch (e) {
			if (e instanceof RequestError || e instanceof Error) {
				returnValue.error = e;
			} else {
				returnValue.error = new Error('Unexpected Error in request wrapper');
			}
		}

		return returnValue;
	}
	/**
	 * Appends query parameter to give URL
	 *
	 * @param url
	 * @param options
	 * @private
	 */
	private appendQueryParameter(url: string, options: GenericSendRequestOptions<T>){
		if (!options.queryParameter) {
			return url;
		}

		let parameter: QueryParameterBase;
		if (isRef(options.queryParameter)) {
			parameter = unref(options.queryParameter);
		} else {
			parameter = options.queryParameter;
		}

		const query = [];

		for (const parameterName of Object.keys(parameter)) {
			query.push(`${encodeURI(parameterName)}=${encodeURI(parameter[parameterName])}`);
		}

		if (query.length === 0) {
			return url;
		}

		return url + '?' + query.join('&');
	}

	/**
	 * Append UUID to url
	 *
	 * @param url
	 * @param options
	 * @param method
	 * @private
	 */
	private appendUUID(url: string, options: GenericSendRequestOptions<T>, method: FetchMethodType){
		if (options.uuid &&
			['GET', 'PUT', 'DELETE', 'PATCH'].includes(method)
		) {
			const uuidUrl = `/${this.extractUuid(options.uuid)}`;

			if (uuidUrl === '/') {
				throw new RequestError(
					'not a valid uuid',
					url,
					{
						code: 500,
						text: 'not a valid uuid'
					},
				);
			}

			url += uuidUrl;
		}

		return url;
	}

	/**
	 * Prepares url for request
	 *
	 * @param options
	 * @param method
	 * @private
	 */
	private prepareUrl(options: GenericSendRequestOptions<T>, method: FetchMethodType) {
		let requestUrl = this.url;

		if (!options) {
			return requestUrl;
		}

		requestUrl = this.appendUUID(requestUrl, options, method);
		requestUrl = this.appendQueryParameter(requestUrl, options);

		return requestUrl;
	}

	/**
	 * Set content-type for header of request
	 * @param data
	 * @param method
	 * @private
	 */
	private setHeaders(
		data: GenericSendRequestOptions<T>['data'],
		method: string
	): Headers {
		const headers = new Headers();
		// To have special fields of API Platform, we have to accept a special json type
		headers.append('Accept', this.acceptedResponseType);

		if (!data ||
			headers.has('Content-Type') ||
			data instanceof FormData
		) {
			return headers;
		}

		if (['POST', 'PUT'].includes(method)) {
			headers.append('Content-Type', 'application/json');
		} else if ('PATCH' === method) {
			// Patch method requires a different type
			headers.append('Content-Type', 'application/merge-patch+json');
		}

		return headers;
	}

	/**
	 * Extract an uuid from a given string
	 *
	 * @param uuidString
	 * @returns {string}
	 */
	private extractUuid(uuidString: string): string {
		const uuidSearch = uuidString.match(/\w{8}-\w{4}-\w{4}-\w{4}-\w{12}/g);
		if (1 === uuidSearch?.length) {
			return uuidSearch[0];
		}

		return '';
	}
}

