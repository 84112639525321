/**
 * This file can be imported in TypeScript only files to have gettext functionality without a VUE component.
 *
 * How to import:
 *
 * import gettext from '@/gettext';
 * const { $gettext } = gettext;
 */
import { createGettext } from 'vue3-gettext';
import type { Translations } from 'vue3-gettext';
import translations from '@/language/translations.json';

export default createGettext(
	{
		availableLanguages: {
			en: 'English',
			de: 'Deutsch',
		},
		defaultLanguage: 'de',
		translations: translations as Translations,
	}
);
