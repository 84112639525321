import type { ElRouterType } from '@energielenker/common-component-bundle';
import { useJwtStore } from '@/stores';

/**
 * Adds a navigation guard to given router instance. The guard will ensure that only authenticated user can navigate to
 * other pages as 'home'.
 *
 * @param router
 */
export default function addNavigationGuardAuthenticated(router: ElRouterType) {
	router.beforeEach(async (to,from, next) => {
		const jwtStore = useJwtStore();
		const maxWaitTime = 10000;

		/*
			At first load of the app it could be that there is no JWT but still a valid refresh token to get a new one.
			For that we have to take account here. So we check the isRenewing flag of the store and see if it will change
			after a while.

			You might think it could be done with a watcher on the (ref-)variable of store but a watcher can only call
			a callback and can't be awaited. So, we do it manually by using a timeout to check at a later time.
		 */
		let wait = 0;
		while (wait < maxWaitTime) {
			if (false === jwtStore.isRenewing) {
				wait = maxWaitTime + 1;
			} else {
				// Wait (by using a timeout) and count up waited time.
				wait+= await new Promise<number>((resolve) => {
					setTimeout(() => {
						resolve(100);
					}, 100);
				});
			}
		}

		// If the user wants to go to a page that requires authentication, but doesn't have a valid token, we redirect them to 'login'.
		const unprotectedRoutes = new Set<string>(['home', 'analytics', 'login']);
		if (!jwtStore.isValid && !unprotectedRoutes.has(to.name as string)) {
			next({ name: 'login' });
		} else {
			next();
		}
	});
}
