<template>
	<div class="popup-overlay">
		<EnbasTransition name="slide-up">
			<div
				v-if="isVisible"
				class="popup-container"
			>
				<div class="popup-body">
					<ul class="popup-menu">
						<li @click.prevent="openDashboardSettings()">
							<a href="#">{{ $gettext('MENU_DASHBOARD_CONFIGURATION') }}</a>
						</li>
						<li><a href="#">{{ $gettext('MENU_CHOOSE_LANGUAGE') }}</a></li>
						<EnbasHeaderLoginLogout />
					</ul>
				</div>
				<div class="popup-footer">
					<ElButton
						full-width
						variant="secondary"
						@click="closePopup"
					>
						{{ $gettext('GENERAL_CLOSE') }}
					</ElButton>
				</div>
			</div>
		</EnbasTransition>
	</div>
</template>

<script setup lang="ts">

import EnbasHeaderLoginLogout from './EnbasHeaderLoginLogout.vue';
import { useDashboardStore } from '@/stores';
import { ref, onMounted } from 'vue';
import EnbasTransition from '@/components/common/EnbasTransition.vue';
const dashboardStore = useDashboardStore();
const emit = defineEmits(['close']);

const closePopup = () => {
	isVisible.value = false;
	setTimeout(() => {
		emit('close');
	}, 200);

};

const openDashboardSettings = () => {
	dashboardStore.toggleSettings();
	closePopup();
};
const isVisible = ref(false)
onMounted(() => {
	setTimeout(() => {
		isVisible.value = true;
	}, 200);
});
</script>

<style  lang="scss">
.popup-overlay{
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #00000080;
	display: table;
}

.popup-container{
	position: fixed;
	width:100%;
	z-index: 1000000;
	bottom:0;
	left:0;
	padding: 8px;
	.popup-body{
		.popup-menu{
			background: var(--mcb-color-primary-white);
			color: var(--mcb-color-primary-white) ;
			border-radius: 6px;
			padding: 8px;
			margin-bottom:8px;
			list-style: none;
			li{
				padding: 8px;
				a{
					color: var(--color-primary-dark-grey);
					text-decoration: none;
					&.logout{
						color: var(--color-red-original)
					}
				}
			}

		}
	}
	.popup-footer{
		.btn{
			color: var(--color-primary-dark-grey);
			font-weight: bold;
			height: 49px;
		}
	}
}

</style>
