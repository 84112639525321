<template>
	<RouterLink
		v-if="isScreenSizeLargerThan('lg')"
		:to="{ name: 'home' }"
	>
		<EnbasLogo :shrink-img="true" />
	</RouterLink>
	<EnbasMainMenu />
	<EnbasSidebarFooter v-if="isScreenSizeLargerThan('lg')" />
</template>

<script setup lang="ts">
import EnbasMainMenu from '@/components/sidebar/EnbasMainMenu.vue';
import EnbasSidebarFooter from '@/components/sidebar/EnbasSidebarFooter.vue';
import EnbasLogo from '@/components/header/EnbasLogo.vue';
import { useScreenSize } from '@/composable/useScreenSize';
const { isScreenSizeLargerThan } = useScreenSize();
</script>
