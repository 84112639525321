import type { StackItemType } from '@/type/store/loadingState/StackItemType';
import { defineStore } from 'pinia';
import { nextTick, ref, watch } from 'vue';

/**
 * Store to show an overlay while page is loading some data. It uses a stack approach to have the overlay remove with
 * last call.
 */
export const useLoadingStore = defineStore('loading', () => {
	const loading = ref(false);
	const stack = ref([] as StackItemType[]);
	let idGenerator = 0;

	watch(stack, async (value) => {
		await nextTick();
		loading.value = value.length > 0;
	}, {
		deep: true
	});

	/**
	 * Adds an entry on to the loading state stack. Whenever there is still an entry on the stack loading overlay is
	 * active. Returned object is needed later on to identify correct entry.
	 *
	 * A name can be given to make it easier to identify the process which requested the loading state
	 *
	 * @param name
	 */
	function addToLoadingStack(name: string = ''): StackItemType {
		const item = {
			id: idGenerator++,
			name
		};
		stack.value.push(item);

		return item;
	}

	/**
	 * Remove an entry from the loading stack. When there is no item left on the stack loading overlay is hidden.
	 *
	 * @param itemToRemove
	 */
	function removeFromLoadingStack(itemToRemove: StackItemType | number) {
		const lengthBefore = stack.value.length;
		stack.value = stack.value.filter((item) => {
			if ('number' === typeof itemToRemove) {
				return item.id !== itemToRemove;
			}

			return 'id' in itemToRemove && item.id !== itemToRemove.id;
		});

		return lengthBefore > stack.value.length;
	}

	/**
	 * Before given callback function is executed the loading state is activated and afterward deactivated. Return value
	 * of the callback is returned. In other words, this wraps the callback with activating and deactivating loading overlay
	 *
	 * @param cb
	 */
	async function wrapCallback<T>( cb: () => Promise<T>) {
		const item = addToLoadingStack(cb.name);
		const value = await cb();
		removeFromLoadingStack(item);

		return value;
	}

	return {
		loading,
		addToLoadingStack,
		removeFromLoadingStack,
		wrapCallback
	};
});
