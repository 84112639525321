import { defineStore } from 'pinia';
import { nextTick, ref } from 'vue';
import { useBackendWrapper } from '@/composable';
import type { RequestDashboardTopBarDataInterface } from '@/type';

const useSystemTime = defineStore('systemTime', () => {

	const timestamp = ref(0);

	void init();

	async function init() {
		// Not waiting for next tick results in a strange vue error. So this is required here
		await nextTick();
		if (timestamp.value === 0) {
			timestamp.value = await getCurrentBackendTimeAsTimestamp();
		}
	}

	async function refresh() {
		timestamp.value = await getCurrentBackendTimeAsTimestamp();
	}

	//TODO this should be fetched via settings, but settings route requires signed in user
	async function getCurrentBackendTimeAsTimestamp(): Promise<number> {
		const backendApi = new useBackendWrapper<RequestDashboardTopBarDataInterface>('api/data/topbar', false);
		const { data: requestGetData, error: requestGetError } = await backendApi.get();

		if (requestGetError || !requestGetData || !requestGetData.data.system_time.timestamp) {
			throw new Error('Unable to retrieve current backend time');
		} else {
			return requestGetData.data.system_time.timestamp;
		}
	}

	return {
		timestamp,
		refresh
	}
})

export default useSystemTime;
