import { ElRouter } from '@energielenker/common-component-bundle';
import { FeatureToggleComponent as feature } from 'vue-feature-toggle';
import gettext from '@/gettext';
import { addNavigationGuardAuthenticated } from '@/router/navigationGuard';

const EnbasSystemSettingsComponent = () => {return import('@/components/site/settings/system/EnbasSystemSettingsComponent.vue');};
const EnbasLicenses = () => {
	return import('@/components/site/settings/licenses/EnbasLicenses.vue');
};
const EnbasSoftwareUpdateComponent = () => {return import('@/components/site/settings/softwareUpdate/EnbasSoftwareUpdate.vue');};
const EnbasAnalytics = () => {return import('@/components/site/analytics/EnbasAnalytics.vue');};
const EnbasComponentsPage = () => { return import('@/components/site/components/componentPage.vue'); };
const EnbasOptimization = () => { return import('@/components/site/optimization/EnbasOptimization.vue'); };
const EnbasMessagesComponent = () => { return import('@/components/site/messages/EnbasMessagesComponent.vue'); };
const EnbasSettingsComponent = () => { return import('@/components/site/settings/EnbasSettingsComponent.vue'); };
const EnbasPhotovoltaicSystem = () => { return import('@/components/site/components/EnbasPhotovoltaicSystem/EnbasPhotovoltaicSystem.vue'); };
const EnbasBatteryStorage = () => { return import('@/components/site/components/EnbasBatteryStorage/EnbasBatteryStorage.vue'); };
const EnbasElectricityMeter = () => { return import('@/components/site/components/EnbasElectricityMeter/EnbasElectricityMeter.vue'); };
const EnbasHeatPump = () => { return import('@/components/site/components/EnbasHeatPump/EnbasHeatPump.vue'); };
const EnbasChargePoint = () => { return import('@/components/site/components/EnbasChargePoint/EnbasChargePoint.vue'); };
const EnbasDashboard = () => { return import('@/components/site/dashboard/EnbasDashboard.vue'); };
const EnbasHeatStorage = () => { return import('@/components/site/components/EnbasHeatStorage/EnbasHeatStorage.vue'); };
const EnbasLoginForm = () => {return import ('@/components/site/login/EnbasLoginForm.vue');};

const { $gettext } = gettext;
const router = ElRouter;

addNavigationGuardAuthenticated(router);

router.addRoute({
	path: '/',
	name: 'home',
	component: EnbasDashboard,
	meta: {
		caption: $gettext('MENU_START'),
	}
});

router.addRoute({
	path: '/login',
	name: 'login',
	component: EnbasLoginForm,
});

router.addRoute({
	path: '/analytics',
	name: 'analytics',
	component: EnbasAnalytics,
	beforeEnter: (to, from) => {
		if (!feature.isVisible('ENBAS_ANALYTICS_PAGE')) {
			return from;
		}
	},
	meta: {
		caption: $gettext('MENU_ANALYTICS'),
	}
});
router.addRoute({
	path: '/components/',
	name: 'components',
	component: EnbasComponentsPage,
	meta: {
		caption: $gettext('MENU_COMPONENTS'),
	},
	children: [
		{
			path: '/components/pv-system/:id?',
			name: 'photovoltaic',
			component: EnbasPhotovoltaicSystem,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_PHOTOVOLTAIC_SYSTEM'),
				url: '',
			},
		},
		{
			path: '/components/electricitymeter/:id?',
			name: 'meters',
			component: EnbasElectricityMeter,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_ELECTRICITY_METER'),
				url: '',
			},
		},
		{
			path: '/components/heatpump/:id?',
			name: 'heatpump',
			component: EnbasHeatPump,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_HEAT_PUMP'),
				url: '',
			},
		},
		{
			path: '/components/batterystorage/:id?',
			name: 'batterystorage',
			component: EnbasBatteryStorage,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_BATTERY_STORAGE'),
				url: '',
			},
		},
		{
			path: '/components/heatstorage/:id?',
			name: 'heatstorage',
			component: EnbasHeatStorage,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_HEAT_STORAGE'),
				url: '',
			},
		},
		{
			path: '/components/chargingpoint/:id?',
			name: 'chargePoint',
			component: EnbasChargePoint,
			props: (route) => {
				const { id } = route.params;

				return {
					id
				};
			},
			meta: {
				caption: $gettext('MENU_CHARGE_POINT'),
				url: '',
			},
		}
	]
});
router.addRoute({
	path: '/optimization',
	name: 'optimization',
	component: EnbasOptimization,
	beforeEnter: (to, from) => {
		if (!feature.isVisible('ENBAS_OPTIMIZATION_PAGE')) {
			return from;
		}
	},
	meta: {
		caption: $gettext('MENU_OPTIMIZATION'),
	}
});
router.addRoute({
	path: '/messages',
	name: 'messages',
	component: EnbasMessagesComponent,
	beforeEnter: (to, from) => {
		if (!feature.isVisible('ENBAS_MESSAGE_PAGE')) {
			return from;
		}
	},
	meta: {
		caption: $gettext('MENU_MESSAGES'),
	}
});
router.addRoute({
	path: '/settings',
	name: 'settings',
	component: EnbasSettingsComponent,
	meta: {
		caption: $gettext('MENU_SETTINGS'),
	},
	children: [
		{
			path: '/settings/system',
			name: 'system',
			component: EnbasSystemSettingsComponent,
			meta: {
				caption: $gettext('MENU_SYSTEM_SETTINGS'),
			},
		},
		{
			path: '/settings/licenses',
			name: 'licenses',
			component: EnbasLicenses,
			meta: {
				caption: $gettext('MENU_SYSTEM_LICENSES'),
			},
		},
		{
			path: '/settings/update',
			name: 'systemupdate',
			component: EnbasSoftwareUpdateComponent,
			meta: {
				caption: $gettext('MENU_SYSTEM_SETTINGS_UPDATE'),
			},
		},

	]
});

export default router;
